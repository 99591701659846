import helper from "utils/helper";
const create = () => {
  const getWorkDetailPublic = async (param) => {
    let api = await helper.setAPI();
    return api.get("api/work/public/get-work-public", {
      params: param,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  };
  const getDriverLoc = async (param) => {
    let api = await helper.setAPI();
    return api.get("api/work/driver-location", {
      params: param,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const getTrackingFromCode = async (param) => {
    let api = await helper.setAPI();
    return api.get(`api/work/get-tracking`, {
      params: param,
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
        "Content-Type": "application/json",
      },
    });
  };

  const getAllWorkRegular = async (param) => {
    try {
      const paramter = {
        ...param,
        active: true,
      };
      let api = await helper.setAPI();
      return api.get(`/api/work/regular/get-all-work-regular`, {
        params: paramter,
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const getWorkRegularById = async (id) => {
    try {
      let api = await helper.setAPI();
      return api.get(`/api/work/regular/get-work-regular-by-id/${id}`);
    } catch (error) {
      console.log("error", error);
    }
  };
  return {
    getWorkDetailPublic,
    getDriverLoc,
    getTrackingFromCode,
    getAllWorkRegular,
    getWorkRegularById,
  };
};

export default { create };
